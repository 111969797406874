import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Button from '../Button';

export const SocialLoginButtonContainer = styled.div<{ disabled?: boolean }>`
  height: 40px;
  div, && button {
    height: 40px;
  }
  margin-bottom: 8px;
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`;

const Icon = styled.img`
  margin-right: 16px;
`;

const StyledButton = styled(Button)`
  && {
    margin-bottom: 16px;
  }
`;

const buttonWithIconProperties = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

type ButtonWithIconProperties = InferProps<typeof buttonWithIconProperties>;

const ButtonWithIcon = ({
  icon, text, alt, ...properties
}: ButtonWithIconProperties) => (
  <StyledButton
    {...properties}
    fullWidth
  >
    <Icon src={icon} alt={alt} width="18" height="18" />
    {text}
  </StyledButton>
);

ButtonWithIcon.propTypes = buttonWithIconProperties;

export default ButtonWithIcon;
