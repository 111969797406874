import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Subtitle2 from '@fuww/library/src/Typography/SubTitle2';
import LoginFormComponent from '@fuww/library/src/Users/LoginForm';
import { useMutation } from '@apollo/client';
import { useIntl } from 'react-intl';
import LinkAndAnchor from '../LinkAndAnchor';
import ServerValidation from '../ServerValidation';
import messages from '../../lib/messages.mjs';
import loginMutation from '../../lib/mutations/login.graphql';

const LoginForm = ({ setLoginSuccess }) => {
  const intl = useIntl();

  const [submitErrors, setSubmitErrors] = useState(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [
    createSession,
  ] = useMutation(loginMutation, {
    onCompleted: (data) => {
      setSubmitDisabled(false);
      localStorage.setItem('accessToken', data.login.accessToken);
      setLoginSuccess(true);
      window.plausible('Login', {
        props: {
          provider: 'EmailAndPassword',
        },
      });
    },
    onError: (error) => {
      setSubmitDisabled(false);
      setSubmitErrors(error);
    },
  });

  const submitHandler = ({ password, username }) => {
    setSubmitDisabled(true);
    createSession({
      variables: {
        username,
        password,
      },
    });
  };

  return (
    <LoginFormComponent
      requiredValidationMessage={intl.formatMessage(messages[
        'applicantForm.fieldRequired'
      ])}
      emailValidationMessage={intl.formatMessage(messages[
        'applicantForm.mustBeValidEmailAddress'
      ])}
      emailLabel={intl.formatMessage(messages.emailAddress)}
      passwordLabel={intl.formatMessage(messages.password)}
      submitLabel={intl.formatMessage(messages.continue)}
      forgotPasswordLink={(
        <LinkAndAnchor
          route="reset-password"
        >
          <Subtitle2 tag="span">
            {intl.formatMessage(messages.forgotPassword)}
          </Subtitle2>
        </LinkAndAnchor>
      )}
      submitHandler={submitHandler}
      submitDisabled={submitDisabled}
      serverValidation={submitErrors && (
        <ServerValidation errors={submitErrors} />
      )}
    />
  );
};

LoginForm.propTypes = {
  setLoginSuccess: PropTypes.func.isRequired,
};

export default LoginForm;
