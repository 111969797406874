import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { TextFieldWithController } from '../TextField';
import Button from '../Button';
import ValidationError from '../ValidationError';
import { Subtitle } from '../Login';
import {
  getEmailValidation,
  getRequiredValidation,
} from '../lib/validationRules';

const LoginFormComponent = ({
  requiredValidationMessage,
  emailValidationMessage,
  emailLabel,
  passwordLabel,
  submitLabel,
  forgotPasswordLink,
  submitHandler,
  submitDisabled,
  serverValidation,
}) => {
  const {
    handleSubmit, errors, control,
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="form-item">
        <TextFieldWithController
          control={control}
          name="username"
          rules={
            getEmailValidation(
              emailValidationMessage,
              requiredValidationMessage,
            )
          }
          variant="filled"
          id="login-email"
          label={emailLabel}
          type="email"
        />
        <ValidationError errors={errors} name="username" />
      </div>
      <div className="form-item">
        <TextFieldWithController
          control={control}
          name="password"
          rules={
            getRequiredValidation(
              requiredValidationMessage,
            )
          }
          variant="filled"
          id="login-password"
          type="password"
          label={passwordLabel}
        />
        <ValidationError errors={errors} name="password" />
      </div>
      <Subtitle anchor={forgotPasswordLink} />
      { serverValidation }
      <Button
        fullWidth
        variant="contained"
        disabled={submitDisabled}
        className="account-login-submit"
        type="submit"
      >
        {submitLabel}
      </Button>
    </form>
  );
};

LoginFormComponent.propTypes = {
  requiredValidationMessage: PropTypes.string.isRequired,
  emailValidationMessage: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  passwordLabel: PropTypes.string.isRequired,
  submitLabel: PropTypes.string.isRequired,
  forgotPasswordLink: PropTypes.node.isRequired,
  submitHandler: PropTypes.func.isRequired,
  submitDisabled: PropTypes.bool,
  serverValidation: PropTypes.node,
};

LoginFormComponent.defaultProps = {
  submitDisabled: false,
  serverValidation: undefined,
};

export default LoginFormComponent;
