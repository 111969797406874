import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import Headline5 from '../Typography/Heading/Headline5';
import Hr from '../Elements/Hr';

const Container = styled.div`
  &&& {
    display: flex;
    width: 100%;
    margin: 48px 0 32px;
  }
`;

const StyledHeadline5 = styled(Headline5)`
  && {
    margin-top: -14px;
    padding: 0 12px;
  }
`;

const dividerPropertyTypes = {
  dividerText: PropTypes.string,
};

type DividerProperties = InferProps<
typeof dividerPropertyTypes
>;

const Divider = ({ dividerText }: DividerProperties) => (
  <Container>
    <Hr backgroundColor="var(--black-disabled)" />
    <StyledHeadline5 tag="div">{dividerText}</StyledHeadline5>
    <Hr backgroundColor="var(--black-disabled)" />
  </Container>
);

Divider.defaultProps = {
  dividerText: undefined,
};

Divider.propTypes = dividerPropertyTypes;

export default Divider;
