import React, { ForwardedRef, forwardRef } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import MUIAlert, { AlertProps } from '@mui/material/Alert';
import Button, { ButtonProps } from '@mui/material/Button';
import breakpoints from './Theme/breakpoints';

export type { AlertColor } from '@mui/material';

export const AlertButton = forwardRef((
  {
    children,
    href,
    ...rest
  }: ButtonProps,
  reference: ForwardedRef<HTMLButtonElement>,
) => (
  <Button color="inherit" size="small" href={href} ref={reference} {...rest}>
    {children}
  </Button>
));

const Alert = forwardRef<HTMLDivElement, AlertProps>(({
  children, severity = 'info', action, ...properties
}, reference) => (
  <MUIAlert
    severity={severity}
    action={action}
    ref={reference}
    {...properties}
  >
    {children}
  </MUIAlert>
));

const alertBarPropertyTypes = {
  siteWide: PropTypes.bool,
  marginBottom: PropTypes.number,
};

type AlertBarProperties = InferProps<typeof alertBarPropertyTypes>;

export const AlertBar = styled.div<AlertBarProperties>`
  margin: ${({ siteWide, marginBottom }) => (
    siteWide ? `24px 24px ${marginBottom ?? 24}px`
      : `0 0 ${marginBottom ?? 24}px`
  )};

  .MuiAlert-message, .MuiButton-label {
    font-family: var(--font-primary);
    font-size: 14px;
  }

  @media (min-width: ${breakpoints.sm}px) {
    margin: ${({ siteWide, marginBottom }) => (
    siteWide ? `24px 24px ${marginBottom ?? 0}px`
      : `0 8px ${marginBottom ?? 24}px`)};
  }
`;

AlertBar.propTypes = alertBarPropertyTypes;

export default Alert;
