import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient } from '@apollo/client';
import ArticleAccess from '@fuww/library/src/Login/ArticleAccess';
import Divider from '@fuww/library/src/Login/Divider';
import Button from '@fuww/library/src/Button';
import { useIntl } from 'react-intl';
import LoginForm from './LoginForm';
import LoginWithProviderForm from './LoginWithProviderForm';
import { Link } from '../../routes.mjs';
import { useSiteContext } from '../SiteContext';
import { saveRouterState, clearRouterState } from '../../lib/routerState';
import messages from '../../lib/messages.mjs';

const ArticleAccessForm = () => {
  const intl = useIntl();
  const { locale } = useSiteContext();
  const { query, route } = useRouter();
  const client = useApolloClient();
  const [loginSuccess, setLoginSuccess] = useState(false);

  useEffect(() => {
    saveRouterState({ route, query });
  }, [route, query]);

  useEffect(() => {
    if (loginSuccess) {
      client.resetStore();
      clearRouterState();
    }
  }, [loginSuccess, locale, client, query]);

  return (
    <ArticleAccess
      invitation={intl.formatMessage(messages.articleAccessInvitation)}
    >
      <LoginForm
        setLoginSuccess={setLoginSuccess}
      />
      <Divider dividerText="OR" />
      <LoginWithProviderForm
        provider="google"
        setLoginSuccess={setLoginSuccess}
      />
      <LoginWithProviderForm
        provider="linkedin"
        setLoginSuccess={setLoginSuccess}
      />
      <Link route="register" passHref>
        <Button fullWidth variant="text" className="create-account">
          {intl.formatMessage(messages['register.createAccount'])}
        </Button>
      </Link>
    </ArticleAccess>
  );
};

ArticleAccessForm.propTypes = {};

ArticleAccessForm.defaultProps = {};

export default ArticleAccessForm;
