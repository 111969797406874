import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ArticleDetail, { DynamicShareButtonContainer, TagChip }
from '@fuww/library/src/Article';
import SocialShare from '@fuww/library/src/SocialShare';
import { useIntl } from 'react-intl';
import dynamic from 'next/dynamic';
import { UserContext } from '../../UserProvider';
import messages from '../../../lib/messages.mjs';
import FormattedDate from '../../FormattedDate';
import { Link } from '../../../routes.mjs';
import shareList from '../../../lib/shareData';
import { useSiteContext } from '../../SiteContext';
import Image from '../../Image';
import getCategoryTitle from '../../../lib/getCategoryTitle';
import getAuthorSlug from '../../../lib/getAuthorSlug';
import LinkAndAnchor from '../../LinkAndAnchor';
import sizes from './sizes';
import loading from '../../../lib/loading';
import { AccessLevel } from '../../../lib/graphql/api/graphql';
import ArticleAccessForm from '../../LoginSignup/ArticleAccessForm';
import ConfirmationAlert from '../../Users/EmailConfirmationAlert';

const DynamicNewsletterComponent = dynamic(() => import('../../NewsletterForm'),
  {
    ssr: false,
    loading,
  });

const AuthorPageLink = ({ linkText, authorEmail }) => (
  authorEmail
  && (
    <LinkAndAnchor
      route="author"
      params={{ authorSlug: getAuthorSlug(authorEmail) }}
    >
      {linkText}
    </LinkAndAnchor>
  )
);

AuthorPageLink.propTypes = {
  linkText: PropTypes.string.isRequired,
  authorEmail: PropTypes.string,
};

AuthorPageLink.defaultProps = {
  authorEmail: null,
};

const logInToRead = (
  accessLevel,
  user,
) => accessLevel !== AccessLevel.FREE && !user;

const isVerificationNeeded = (accessLevel, user) => (
  accessLevel !== AccessLevel.FREE && user && !user.verified
);

const Main = ({
  article,
  ArticleComponent,
  showLoader,
  sourceSet,
  imageUrls,
  metaImageUrl,
}) => {
  const {
    body, category, title, insertedAt, tags, labels,
    partnerContent, pressRelease,
    imageCaption, accessLevel,
  } = article;
  const { origin, url } = useSiteContext();

  const creator = { ...article.creator };

  const { hasLocalNews, locale } = useSiteContext();
  const intl = useIntl();
  const filteredShareList = shareList({
    title,
    media: `${origin}${metaImageUrl}`,
    url,
    locale,
  }).filter((share) => !share.checkMedia || imageUrls[0]);

  const { name: author, email } = creator;

  const { user } = useContext(UserContext);

  return (
    <ArticleComponent
      title={title}
      category={category || { title: '' }}
      translatedCategoryTitle={getCategoryTitle(category, hasLocalNews, intl)}
      articleBy={(
        <AuthorPageLink
          linkText={intl.formatMessage(messages.articleBy, { author })}
          authorEmail={email}
        />
      )}
      insertedAt={<FormattedDate value={insertedAt} />}
      body={body}
      image={(
        <Image
          placeholder={imageUrls[0]}
          src={imageUrls[1]}
          srcSet={sourceSet}
          alt={title}
          sizes={sizes}
          finalFetchpriority="high"
          placeholderFetchpriority="high"
          loadStrategy="instant"
          width="100%"
          objectFit="cover"
          preload
        />
      )}
      imageCaption={imageCaption}
      socialButtons={<SocialShare data={filteredShareList} />}
      labels={labels}
      sponsorMessage={
        partnerContent
          ? intl.formatMessage(messages.partnerContent).toUpperCase() : null
      }
      pressReleaseMessage={
        pressRelease
          ? intl.formatMessage(messages.pressReleaseMessage) : null
      }
      showLoader={showLoader}
      logInToRead={logInToRead(accessLevel, user)}
      LoginComponent={ArticleAccessForm}
      isMemberContent={accessLevel !== AccessLevel.FREE}
      isVerificationNeeded={isVerificationNeeded(accessLevel, user)}
      VerificationComponent={ConfirmationAlert}
    >
      <div>
        {tags.map(({ title: tagTitle, slug }) => (
          <Link
            route="tag"
            params={{ slug }}
            passHref
            key={`article-${tagTitle}`}
          >
            <TagChip
              label={tagTitle}
            />
          </Link>
        ))}
      </div>
      <DynamicShareButtonContainer center>
        <SocialShare data={filteredShareList} />
      </DynamicShareButtonContainer>
      <DynamicNewsletterComponent showDescriptiveText={false} />
    </ArticleComponent>
  );
};

Main.propTypes = {
  article: PropTypes.shape({
    category: PropTypes.shape({
      title: PropTypes.string,
      path: PropTypes.string,
    }),
    title: PropTypes.string,
    creator: PropTypes.shape({}),
    insertedAt: PropTypes.string,
    imageCaption: PropTypes.string,
    tags: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      slug: PropTypes.string,
    })),
    body: PropTypes.string,
    reference: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.string),
    partnerContent: PropTypes.bool.isRequired,
    pressRelease: PropTypes.bool.isRequired,
    accessLevel: PropTypes.string,
  }),
  ArticleComponent: PropTypes.elementType,
  showLoader: PropTypes.bool,
  sourceSet: PropTypes.string.isRequired,
  imageUrls: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  metaImageUrl: PropTypes.string.isRequired,
};

Main.defaultProps = {
  article: {},
  ArticleComponent: ArticleDetail,
  showLoader: false,
};

export default Main;
