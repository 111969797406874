import React, { useState, useCallback, useContext } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import Alert, { AlertButton, AlertBar } from '@fuww/library/src/Alert';
import HiddenLoader from '@fuww/library/src/HiddenLoader';
import { useMutation, ApolloError } from '@apollo/client';
import { useIntl } from 'react-intl';
import messages from '../../lib/messages.mjs';
import { UserContext } from '../UserProvider';
import resendVerificationEmail
  from '../../lib/mutations/resendVerificationEmail.graphql';

const confirmationAlertPropertyTypes = {
  marginBottom: PropTypes.number,
};

export type ConfirmationAlertProperties = InferProps<
typeof confirmationAlertPropertyTypes
>;

const ConfirmationAlert = ({ marginBottom }: ConfirmationAlertProperties) => {
  const [requestSuccess, setRequestSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApolloError | boolean>(false);
  const { user, loading: userLoading } = useContext(UserContext);

  const [
    resendEmail,
  ] = useMutation(resendVerificationEmail, {
    onCompleted: () => {
      setRequestSuccess(true);
      setLoading(false);
    },
    onError: (graphqlError) => {
      setError(graphqlError);
      setLoading(false);
    },
  });

  const clickHandler = useCallback(() => {
    resendEmail();
    setLoading(true);
  }, [resendEmail]);

  const intl = useIntl();

  if (userLoading) {
    return <HiddenLoader />;
  }

  if (requestSuccess) {
    return (
      <AlertBar siteWide marginBottom={marginBottom}>
        <Alert
          severity="success"
        >
          {intl.formatMessage(messages.emailResent)}
        </Alert>
      </AlertBar>
    );
  }

  if (error) {
    return (
      <Alert
        severity="error"
      >
        {intl.formatMessage(messages.errorRequestingEmail)}
      </Alert>
    );
  }

  return user && !user.verified && (
    <AlertBar siteWide marginBottom={marginBottom ?? 0}>
      <Alert
        severity="warning"
        action={(
          <AlertButton
            disabled={loading}
            onClick={() => {
              clickHandler();
            }}
            className="resend-confirmation-email"
          >
            {intl.formatMessage(messages.resendEmail)}
          </AlertButton>
        )}
      >
        {intl.formatMessage(messages['register.emailNotVerified'])}
      </Alert>
    </AlertBar>
  );
};

ConfirmationAlert.propTypes = confirmationAlertPropertyTypes;

export default ConfirmationAlert;
