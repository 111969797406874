import React from 'react';
import PropTypes, { InferProps } from 'prop-types';
import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { mediumRoundedCorners } from '../RoundedCorners';
import colors from '../Theme/colors';
import Headline4 from '../Typography/Heading/Headline4';

const ArticleAccessCallout = styled(Headline4)`
  text-align: center;
`;

const ArticleAccessLoginContainer = styled.div`
  && {
    background-color: ${colors.black};
    color: ${colors.white};
    padding: 12px 12px 32px;
    margin: 0 auto;
    border: none;
    ${mediumRoundedCorners};
    a, a span, .MuiTypography-root {
      color: white;
    }
    hr {
      background-color: white;
    }

    @media (min-width: ${breakpoints.sm}px) {
      padding: 16px 16px 32px;
      margin-bottom: 32px;
      max-width: 375px;
      border: 1px solid var(--black-disabled);
    }


    button {
      border: none;
    }
    button > svg {
      margin: 0 auto 16px;
      display: none;

      @media (min-width: ${breakpoints.sm}px) {
        display: block;
      }
    }
    button > img {
      width: fit-content;
    }

    .MuiTextField-root {
      margin: 20px 0 0;
      width: 100%;
      background-color: ${colors.white};
    }
    .MuiButtonBase-root {
      width: 100%;
      margin: 20px 0 0;
      background-color: white;
      color: black;
    }
  }
`;

const articleAccessPropertyTypes = {
  children: PropTypes.node.isRequired,
  invitation: PropTypes.string.isRequired,
};

type ArticleAccessProperties = InferProps<
typeof articleAccessPropertyTypes
>;

const ArticleAccess = ({
  invitation,
  children,
}: ArticleAccessProperties) => (
  <ArticleAccessLoginContainer>
    <ArticleAccessCallout tag="div">
      { invitation }
    </ArticleAccessCallout>
    { children }
  </ArticleAccessLoginContainer>
);

ArticleAccess.propTypes = articleAccessPropertyTypes;

export default ArticleAccess;
